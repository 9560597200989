@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';
@use './mixins';

.bh-footer {
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: variables.$default-spacing;
  padding-bottom: 35px;
  text-align: center;

  bh-footer-legal-link a,
  p {
    font-size: variables.$font-size-small;
    line-height: 15px;
  }

  p {
    color: variables.$dark-gray;
    margin: 0;
  }

  bh-footer-legal-link {
    a {
      color: variables.$dark-teal;
      display: inline-block;
      font-weight: normal;

      &:visited,
      &:focus,
      &:active {
        color: variables.$dark-teal;
      }

      &:focus {
        @include mixins.bh-focus-indicator();
      }
    }

    &:not(:last-child) {
      .bh-footer__legal-link {
        --link-spacing: 10px;

        border-right: 1px solid colors.$nimbus;
        margin-right: var(--link-spacing);
        padding-right: var(--link-spacing);
      }
    }
  }

  @media screen and (max-width: variables.$mobile-breakpoint) {
    background:
      url('../assets/hill-light-with-dark.svg') 0 0 no-repeat,
      linear-gradient(180deg, variables.$white 0 10px, colors.$navy 10px 100%) no-repeat;
    background-size: 100% auto;
    margin-top: 0;
    min-width: variables.$max-content-mobile-footer-width;
    padding-top: 100px;

    bh-footer-legal-link a,
    p {
      color: variables.$white;
    }

    bh-footer-legal-link {
      a {
        margin-bottom: 10px;

        &:visited,
        &:focus,
        &:active {
          color: variables.$white;
        }

        &:focus {
          @include mixins.bh-focus-indicator();
        }
      }
    }

    p {
      margin-top: 10px;
    }

    img {
      margin-top: 35px;
    }
  }
}

a.bh-terms__legal-link {
  color: colors.$dark-blue;
  display: inline-block;
  font-size: variables.$font-size-normal;
  font-weight: normal;

  &:visited,
  &:focus,
  &:active {
    color: colors.$dark-blue;
  }

  &:focus {
    @include mixins.bh-focus-indicator();
  }

  &--font {
    color: colors.$navy;
    font-size: variables.$font-size-default;
    text-decoration: none;
  }

  &--color {
    color: colors.$navy;
    text-decoration: underline;
  }
}
