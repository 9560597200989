@use './node_modules/@bh/design-system/src/style/colors';
@use '../styles/variables';

.bh-card-group {
  display: flex;
  flex-direction: column;
  gap: variables.$large-spacing;

  &__card {
    background: variables.$white;
    border-radius: variables.$default-radius;
    box-shadow: 0 0 8px colors.$shadow;
    box-sizing: border-box;
    padding: variables.$medium-spacing variables.$medium-spacing variables.$large-spacing variables.$large-spacing;
    width: 100%;

    &.mat-mdc-radio-button {
      display: flex;

      .mdc-form-field {
        align-items: flex-start;
        flex-grow: 1;
        justify-content: space-between;
      }

      label {
        cursor: pointer;
        flex-grow: 1;
        margin: 0;
      }
    }

    &--address {
      &.mat-mdc-radio-button {
        label {
          text-transform: capitalize;
        }
      }
    }
  }
}
