// colors
$black: #000000;
$white: #ffffff;
$red: #ff0000;
$pink: #f3cfc6;
$hot-pink: #aa336a;
$gray-shade: #eeeded;
$gray-shade-1: #f9fcff;
$gray-shade-2: #f8fcfe;
$dark-teal: #277489;
$dark-gray: #505050;
$dark-gray-shade-1: #666666;
$dark-gray-shade-2: #808080;
$dark-blue: #1a475f;
$light-blue: #28748a;
$alice-blue: #EEF5FA;
$bg-readonly: rgb(221 221 221 / .3);
$gray-shade-3:#dddddd;
$dark-gray-shade-3: #707070;
$dark-gray-shade-4: #dddddd4d;
$dark-blue-shade-2: #2C78A0;
$gray-shade-4: #B3B3B3;
$dark-blue-shade-2: #101828;
$gray-shade-5: #333333;
$dark-blue-shade-3: #141823;
$dark-gray-shade-5: #707070;
$dark-gray-shade-6: #464646;

// content/container widths
$content-container-width-d: 608px;
$content-container-width-m: 425px;
$content-container-min-height-d: 920px;

$max-content-mobile-width: 343px;
$max-content-mobile-footer-width: 375px;
$max-content-mobile-header-width: 334px;
$max-content-width: 448px;
$line-width: 300px;

$mobile-breakpoint: 768px;

// spacing (padding/margin)
$small-spacing: .3125rem;
$default-spacing: .5rem;
$medium-spacing: .75rem;
$large-spacing: 1rem;
$extra-large-spacing: 1.75rem;
$huge-spacing: 2.5rem;

// borders & outlines
$default-radius: .5rem;
$border-radius-tab: 1.25rem;
$border-radius-tablist: 1.5625rem;
$border-width-default: .0625rem;

// font size
$font-size-default: 1rem;
$font-size-message: 1.1rem;
$font-size-small: .75rem;
$font-size-normal: .875rem;
$font-size-medium: 1.1875rem;
$font-size-medium-2: 1.375rem;
$default-size: 1.5rem;
/* stylelint-disable-next-line dollar-variable-pattern */
$tooltip-size: 12px;
/* stylelint-disable-next-line dollar-variable-pattern */
$tooltipicon-size: 20px;
$font-size-medium-3: 24px;
$font-size-medium-4: 19px;
$font-size-medium-5: 16px;
$font-size-medium-6: 14px;

// line height
$small-line-height: 1.25rem;
$button-line-height: 1.5625rem;
$medium-line-height: 1.6875rem;
$large-line-height: 2rem;
$default-line-height: 1.5rem;

// font weight
$light-font-weight: 100;
$medium-font-weight: 400;
$normal-font-weight: 700;
$bolder-font-weight: 800;
$default-font-weight: 600;

$font-family: 'Mulish', arial, helvetica, sans-serif !important;

// spinner
$spinner-size: 44px; // this must match the [diameter] size set in app.component.html for the spinner
$spinner-border-size: 4px;
