@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';
@use './mixins';

a,
button {
  cursor: pointer;
}

.bh-button-pair,
.bh-button-single {
  box-sizing: border-box;
  display: grid;
  margin: 3rem 0 3.5rem;
}

.bh-button-pair {
  grid-gap: variables.$large-spacing;
  grid-template-columns: max-content 1fr; // allows second button to expand to fill remaining space
  width: 100%;

  &--equal {
    grid-template-columns: 1fr 1fr; // gives buttons equal width

    @media (max-width: variables.$mobile-breakpoint) {
      grid-template-columns: 1fr; // stacks full-width buttons
    }
  }

  &--stacked {
    grid-template-columns: 1fr; // stacks full-width buttons
  }
}

.bh-button-single {
  grid-template-columns: 1fr; // allows button to expand to full width
}

@mixin bh-button {
  align-items: center;
  border-radius: variables.$default-radius;
  border-style: solid;
  border-width: variables.$border-width-default;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: variables.$font-size-medium;
  font-weight: variables.$default-font-weight;
  height: 3.6875rem;
  justify-content: center;
  line-height: variables.$button-line-height;
  padding: variables.$large-spacing $extra-large-spacing;
  text-decoration: none;

  &:focus {
    @include mixins.bh-focus-indicator();
  }
}

@mixin bh-button--primary {
  @include bh-button();

  background: colors.$yellow;
  border-color: colors.$yellow;
  color: colors.$charcoal;

  &:hover,
  &:focus {
    background: colors.$gold;
    border-color: colors.$gold;
    color: colors.$charcoal;
  }

  &:active,
  &:visited {
    color: colors.$charcoal;
  }

  &:disabled,
  &[aria-disabled='true'] {
    background: colors.$daydream;
    border-color: colors.$daydream;
    color: colors.$cloudsurf;
  }
}

@mixin bh-button--secondary {
  @include bh-button();

  background: variables.$white;
  border-color: colors.$navy;
  color: colors.$navy;

  &:hover,
  &:focus {
    background: colors.$light-teal;
    color: colors.$navy;
  }

  &:active,
  &:visited {
    color: colors.$navy;
  }

  &:disabled,
  &[aria-disabled='true'] {
    border-color: colors.$cloudsurf;
    color: colors.$cloudsurf;
  }
}

@mixin bh-button--inline-link {
  background: transparent;
  border: 0;
  color: colors.$dark-blue;
  cursor: pointer;
  display: inline;
  font-size: inherit;
  font-weight: variables.$default-font-weight;
  height: auto;
  line-height: inherit;
  padding: 0;

  &:hover,
  &:focus {
    color: colors.$navy;
  }
}

.bh-button {
  // Use bh-button--primary for "Continue" buttons
  &--primary {
    @include bh-button--primary();
  }

  // Use bh-button--secondary for "Previous" buttons
  &--secondary {
    @include bh-button--secondary();
  }

  &--tertiary {
    @include bh-button--secondary();

    border-color: variables.$dark-teal;
    border-width: .125rem;
    color: variables.$dark-teal;
    height: auto;
  }

  &--search-icon {
    @include bh-button;

    background-color: transparent;
    background-image: url('../assets/search-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    border-color: transparent;
    height: 3rem;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: variables.$small-spacing;
    top: variables.$small-spacing;
    width: 3rem;

    &:hover,
    &:focus {
      background-image: url('../assets/search-icon-blue.svg');
    }

    &:focus {
      @include mixins.bh-focus-indicator();
    }
  }

  &--inline-link {
    @include bh-button--inline-link();
  }
}
