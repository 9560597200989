@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';

// Heading Styles from Figma

// Note: bh-heading-<num> styles are applied only if bh-heading is also present.
.bh-heading {
  font-family: variables.$font-family;

  // This specificity is intentional. Using both classes as a compound selector
  // ensures these styles override Angular Material styles.
  &.bh-heading--1 {
    font-size: variables.$default-size;
    font-weight: variables.$bolder-font-weight;
    line-height: variables.$large-line-height;
  }

  &.bh-heading--2,
  &.bh-heading--3,
  &.bh-heading--4 {
    font-weight: variables.$normal-font-weight;
  }

  &.bh-heading--2 {
    font-size: variables.$font-size-medium-2;
    line-height: variables.$large-line-height;
  }

  &.bh-heading--3 {
    font-size: variables.$font-size-medium;
    line-height: variables.$medium-line-height;
  }

  &.bh-heading--4 {
    font-size: variables.$font-size-default;
    line-height: variables.$default-size;
  }
}
