// Need to disable the import order rule here because the @use declaration needs to be first!
/* stylelint-disable no-invalid-position-at-import-rule */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './styles/bh-mat-color-palettes' as bh-palettes;
@use './styles/variables' as vars;
// Plus imports for other components in your app.
@import './styles/variables';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/* stylelint-disable-next-line function-no-unknown */
$bh-login2-primary: mat.define-palette(bh-palettes.$dark-blue-palette);
//$bh-login2-accent: mat.define-palette(mat.$pink-palette, a200, a100, a400);
/* stylelint-disable-next-line function-no-unknown */
$bh-login2-accent: mat.define-palette(mat.$pink-palette);

// The warn palette is optional (defaults to red).
/* stylelint-disable-next-line function-no-unknown */
$bh-login2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
/* stylelint-disable-next-line function-no-unknown */
$bh-login2-theme: mat.define-light-theme((color: (primary: $bh-login2-primary,
accent: $bh-login2-accent,
warn: $bh-login2-warn,
)));

.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mdc-tooltip__surface {
      align-items: center;
      background-color: vars.$dark-blue;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      font-size: vars.$tooltip-size;
      font-style: normal;
      font-weight: 600;
      gap: 4px;
      line-height: 18px;
      max-width: 300px;
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bh-login2-theme);

/* You can add global styles to this file, and also import other style files */

@import './node_modules/@bh/design-system/src/style/font';
@import './styles/utils';
@import './styles/animations';
@import './styles/global';
@import './styles/alert';
@import './styles/button';
@import './styles/grid';
@import './styles/header';
@import './styles/headings';
@import './styles/page';
@import './styles/footer';
@import './styles/progress-bar';
@import './styles/form';
@import './styles/card';
@import './styles/tabs';
@import './styles/dialog';
