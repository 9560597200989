@use 'node_modules/@bh/design-system/src/style/colors';
@use './variables';
@use './mixins';

// Override font-size: 16px applied to every element by font.scss
// (This allows elements to inherit font-size from their parent element)
* {
  font-size: inherit;
}

html,
body {
  // stylelint-disable-next-line scale-unlimited/declaration-strict-value
  font-size: 1rem; // Default font-size should be 1rem (NOT 16px) to enable dynamic text sizing
  height: 100%;
}

body {
  background:
    linear-gradient(165deg,
    variables.$white 35%,
    variables.$gray-shade-1 0,
    variables.$gray-shade-2 45%,
    colors.$light-teal 0,
    colors.$light-teal 100%)
    fixed
    no-repeat;
  margin: 0;
}

main {
  --backround-height: 95px;
  --padding-top-d: 24px;
  --padding-bottom-d: 40px;
  --padding-left-right-d: 80px;

  align-items: center;
  background: url('../assets/hill-light.svg') 0 0 variables.$white no-repeat;
  background-size: variables.$content-container-width-d auto;
  border-radius: variables.$default-radius;
  box-shadow: 0 0 8px colors.$shadow-fab;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: variables.$default-spacing auto;
  min-width: variables.$max-content-width;
  padding:
    calc(var(--padding-top-d) + var(--backround-height))
    var(--padding-left-right-d)
    var(--padding-bottom-d);
  width: calc(#{variables.$content-container-width-d} - (2 * var(--padding-left-right-d)));

  > article {
    max-width: variables.$max-content-width;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: variables.$mobile-breakpoint) {
    --padding-top-m: 40px;
    --padding-bottom-m: 40px;
    --padding-left-right-m: 16px;

    background: variables.$white;
    border-radius: 0;
    box-shadow: inset 0 10px 8px -10px colors.$shadow-fab;
    margin-bottom: 0;
    margin-top: 0;
    min-height: auto;
    min-width: variables.$max-content-mobile-width;
    padding:
      var(--padding-top-m)
      var(--padding-left-right-m)
      var(--padding-bottom-m);
    width: auto;
  }
}

a {
  &:visited,
  &:focus,
  &:active {
    color: colors.$navy;
  }

  &:focus {
    @include mixins.bh-focus-indicator();
  }
}

span,
sub {
  &.bh {
    &--marked {
      color: colors.$magenta;
    }
  }
}

.emailsection {
  background-color: colors.$light-teal;
  border-radius: variables.$default-radius;
  margin-bottom: .5rem;
  margin-top: -.438rem;
  padding: variables.$large-spacing;

  p {
    font-size: variables.$font-size-medium;
    font-weight: variables.$normal-font-weight;
    margin: 0;
  }
}

h1 {
  margin-top: 0;

  &.bh--heading {
    color: colors.$charcoal;
    font-family: variables.$font-family;
    font-size: variables.$default-size;
    font-weight: variables.$bolder-font-weight;
    line-height: variables.$large-line-height;
    margin-bottom: 1rem;
    margin-top: variables.$large-spacing;
  }
}

.material-icons {
  font-family: 'Material Icons', serif !important;
}

.mat-mdc-progress-spinner {
  height: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
  left: calc((100% - #{variables.$spinner-size}) / 2);
  position: fixed !important; /* stylelint-disable-line declaration-no-important */
  top: calc((100% - #{variables.$spinner-size}) / 2);
  width: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
  z-index: 99999;

  @media screen and (prefers-reduced-motion: reduce) {
    &--index-page {
      animation: none;
      border: 4px solid colors.$dark-blue;
      border-radius: 50%;
      border-right-color: transparent;
      height: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
      width: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
    }
  }

  &--index-page {
    animation: spin 1s infinite linear; /* stylelint-disable-line no-unknown-animations */
    border: 4px solid colors.$dark-blue;
    border-radius: 50%;
    border-right-color: transparent;
    height: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
    width: calc(#{variables.$spinner-size} - (#{variables.$spinner-border-size} * 2));
  }

  circle {
    stroke: colors.$dark-blue;
  }
}

.has-errors {
  border-color: colors.$magenta !important;
  color: colors.$magenta !important;

  .label-field {
    color: colors.$magenta !important;
  }

  .input-field {
    border-color: colors.$magenta !important;
  }

  .select-field {
    border-color: colors.$magenta !important;
  }
}

.privacypolicy {
  border: .063rem solid colors.$daydream;
  border-radius: variables.$default-spacing;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  position: relative;

  .privacydetails {
    margin-left: 1rem;
    text-align: left;
    white-space: normal;

    .bh-terms__legal-link,
    a {
      display: inline;
    }
  }

  .details {
    color: colors.$charcoal;
    font-size: variables.$font-size-default;
    font-weight: variables.$medium-font-weight;
    line-height: variables.$default-size;
    margin: 0;
    text-align: left;
  }

  .detailsmsg {
    font-size: variables.$font-size-normal;
  }
}

.privacy-policy {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;

  .info-details {
    color: colors.$cloudsurf;
    font-size: variables.$font-size-normal;
    font-weight: variables.$medium-font-weight;
    line-height: variables.$small-line-height;
    margin: 0;
    text-align: left;
  }

  .detailsmsg {
    font-size: variables.$font-size-normal;
  }
}

/* To remove up and down arrows in number field - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

.error-text {
  color: colors.$magenta;
  font-size: variables.$font-size-normal;
  font-weight: variables.$medium-font-weight;
  line-height: variables.$default-size;
  margin: 0 0 0 .313rem;
  text-align: left;
}

.checkbox-error {
  margin-top: -1.375rem;
}

.invalid-feedback {
  border-color: colors.$magenta;
  display: flex;
  flex-direction: row;
  margin: .25rem 0 0;
  padding: 0;
  text-align: left;
}

.field-content {
  a {
    color: variables.$dark-teal;
    display: inline;
    font-size: variables.$font-size-medium;
  }

  &--description-font {
    color: colors.$charcoal;
    font-size: variables.$font-size-medium;
  }

  &--font {
    font-weight: variables.$default-font-weight;
    margin-bottom: 2.625rem !important;
  }

  &--margin {
    margin-bottom: 1.813rem !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &--no-list {
    font-size: variables.$font-size-default;
    font-weight: variables.$normal-font-weight;
  }

  &--border {
    border-top: .063rem solid colors.$nimbus;
    margin-top: 1.5rem;
    width: variables.$line-width;
  }

  &--dark {
    color: variables.$black;
  }
}

.default-icon {
  img {
    margin-bottom: 1rem;
  }
}
// Override Bing Maps styles to adjust placement of zoom buttons
// stylelint-disable-next-line selector-class-pattern
.map-viewport .MicrosoftMap .NavBar_Container {
  right: 1.875rem !important;
  top: 1.875rem !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.timeout-popup-dialog {
  position: absolute !important;
  top: 95px;

  @media (max-width: variables.$mobile-breakpoint) {
    max-width: fit-content !important;
  }

  .mat-mdc-dialog-container {
    border-radius: variables.$default-radius;
    height: 100%;
    overflow: hidden;
    padding: 0;
    text-align: center;
    width: 608px;

    @media (max-width: variables.$mobile-breakpoint) {
      width: 375px;
    }
  }
}

.backdropbackground {
  background-color: variables.$black;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: .5 !important;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 900;
}

.grecaptcha-badge {
  display: none;
}

.signup-otp {
  .has-errors {
    .mdc-checkbox {
      // stylelint-disable-next-line selector-max-compound-selectors
      .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
        opacity: 0;
      }

      // stylelint-disable-next-line selector-max-compound-selectors
      .mdc-checkbox__background {
        border-color: colors.$magenta !important;
      }
    }

    label {
      color: colors.$magenta;
    }
  }
}

.explore-message {
  color: colors.$charcoal;
  font-size: variables.$font-size-medium;
  font-weight: variables.$medium-font-weight;
  line-height: variables.$default-size;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center;
}

.login-page {
  .redirect_link {
    display: inline-block;

    &-button {
      background: none;
      border: 0;
      color: colors.$dark-blue;
      font-size: variables.$font-size-default;
      font-weight: variables.$default-font-weight;
      height: auto;
      line-height: 1.688rem;
      margin-bottom: .75rem;
      text-decoration: underline;
    }
  }

  .form {
    margin-top: 2.813rem;

    .label-field {
      color: colors.$mud-puddle;
    }

    &__field {
      &:focus-within {
        .label-field {
          color: colors.$dark-blue;
        }
      }
    }
  }

  .bh--links {
    color: colors.$charcoal;
    font-size: variables.$font-size-medium;
    font-weight: variables.$default-font-weight;
    line-height: 1.688rem;
    margin-bottom: 4rem;
    text-align: center;

    a {
      color: colors.$dark-blue;
      display: inline;
      font-size: variables.$font-size-medium;
      text-decoration: underline;
    }
  }

  .bh-form__field {
    margin-bottom: 1.813rem;
  }

  .bh-button-single {
    margin: 1rem 0;
  }
}

.lob-number-list {
  display: block;

  .list {
    display: inline-block;
    width: 50%;

    @media (max-width: variables.$mobile-breakpoint) {
      display: block;
      width: 100%;

      .field-content {
        &--margin {
          margin-bottom: 1rem !important;
        }

        &--no-margin {
          margin-bottom: 1rem !important;
        }
      }
    }

    a {
      color: variables.$dark-teal;
      font-size: variables.$font-size-default;
      font-weight: variables.$normal-font-weight;
      text-decoration: none;
    }
  }
}
