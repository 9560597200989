@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';

.bh-alert {
  margin-bottom: 21px;
  padding: 16px;

  a {
    color: $navy;
    display: inline-block;
    font-weight: normal;
    text-decoration: underline;
  }

  h3 {
    font-size: var(--h3-font-size);
    font-weight: var(--h3-font-weight);
    line-height: var(--h3-line-height);
    margin-bottom: variables.$default-spacing;
  }

  &--error {
    background: colors.$anthill;
    border-radius: variables.$default-radius;
  }

  &__container {
    text-align: left;
  }

  &__header {
    font-size: variables.$font-size-medium;
    font-weight: 700;
    line-height: variables.$default-line-height;
    margin-bottom: variables.$default-spacing;
    margin-top: 0;

    &--error {
      color: colors.$magenta;
    }
  }

  &__body {
    color: colors.$charcoal;

    .bh-alert__paragraph {
      margin: 1.5rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
