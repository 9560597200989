@use './node_modules/@bh/design-system/src/style/colors';
@use '../styles/variables';

.my-custom-snackbar {
  background: colors.$enchanted-forest !important;
  border-radius: .5rem;
  box-shadow: 2px 2px 5px rgb(0 0 0 / .1);
  color: variables.$black !important;
  font-size: variables.$font-size-normal;
  font-weight: variables.$medium-font-weight;
  height: auto;
  left: calc(50% - 608px/2);
  line-height: variables.$small-line-height;
  position: absolute;
  top: 0;
  width: 38rem;

  &::before {
    background: url('../assets/tick.svg') colors.$light-green no-repeat center;
    background-size: .625rem .625rem;
    border-radius: 50%;
    content: '';
    height: 1.25rem;
    left: .938rem;
    position: absolute;
    width: 1.25rem;
  }

  &.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      background: none;
      box-shadow: none;
      max-width: 100%;
      min-width: 100%;
    }

    .mdc-snackbar__label {
      color: colors.$charcoal;
    }

    .mat-mdc-snack-bar-action {
      color: colors.$dark-blue !important;
      font-size: variables.$font-size-normal;
      font-weight: variables.$default-font-weight;
      line-height: variables.$small-line-height;
      text-decoration: underline;
    }
  }

  .mat-mdc-simple-snack-bar {
    margin-left: 1.875rem;
  }

  &_warning {
    background: colors.$anthill !important;
    border: 1px solid colors.$magenta;
    border-radius: .5rem !important;
    box-shadow: 2px 2px 5px rgb(0 0 0 / .1);

    &::before {
      background: url('../assets/white_cross.svg')  colors.$magenta no-repeat center;
      border-radius: 50%;
      content: '';
      height: 1.25rem;
      left: .98rem;
      position: absolute;
      width: 1.25rem;
    }

    .mat-mdc-simple-snack-bar {
      color: colors.$charcoal;
      margin-left: 1.875rem;
    }
  }
}

// add tick image in the below commented code

.mat-mdc-snack-bar-container.my-custom-snackbar {
  margin: 0;
  min-width: variables.$content-container-width-d;
}

.mat-mdc-snack-bar-container.my-custom-snackbar_warning {
  margin: 0;
  min-width: variables.$content-container-width-d;
}

@media screen and (max-width: variables.$mobile-breakpoint) {
  .mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
    left: 0;
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 !important;
  }

  .my-custom-snackbar {
    left: 0;
    /* stylelint-disable-next-line declaration-no-important */
    max-width: 100% !important;
    /* stylelint-disable-next-line declaration-no-important */
    min-width: 100% !important;
    width: 100%;
  }

  .my-custom-snackbar_warning {
    height: auto;
  }
}

bh-stepper + bh-employer-logo {
  display: block;
  margin-top: variables.$huge-spacing;
}
