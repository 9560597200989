@import './variables';

mat-grid-tile {
  &.bh-grid-list {
    &--left {
      .mat-grid-tile-content {
        align-items: start;
        justify-content: left;
        position: relative;
      }
    }

    &--right {
      .mat-grid-tile-content {
        align-items: end;
        justify-content: right;
        position: relative;
      }
    }
  }
}
