@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';

body {
  .page {
    &-description {
      margin-bottom: 1rem;

      &--large {
        font-size: variables.$font-size-medium;
      }
    }
  }
}
