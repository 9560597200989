@import './variables';

.bh-header {
  --header-height-d: 74px;
  --header-padding-top-bottom-d: 16px;
  --header-padding-left-right-d: 20px;

  background-color: $white;
  box-shadow: 2px 2px 5px 0 $shadow-fab;
  display: block;
  height: calc(var(--header-height-d) - (2 * var(--header-padding-top-bottom-d)));
  padding: var(--header-padding-top-bottom-d) var(--header-padding-left-right-d);
  width: auto;

  .mat-grid-list {
    height: calc(var(--header-height-d) - (2 * var(--header-padding-top-bottom-d)));
  }

  .bh-logo {
    &__img {
      height: calc(var(--header-height-d) - (2 * var(--header-padding-top-bottom-d)));
    }

    &__description {
      align-self: end;
      color: $navy;
      margin-bottom: 4px;
    }
  }

  .bh-grid-list--right {
    overflow: visible; // accommodates visible focus indicator on Help link
  }

  .bh-button--tertiary {
    font-size: $font-size-default;
    line-height: $default-line-height;
    padding: .375rem 1.625rem;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    --header-height-m: 56px;

    height: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)));
    min-width: $max-content-mobile-header-width;

    .mat-grid-list {
      height: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)));
    }

    .bh-logo {
      &__img {
        height: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)));
      }
    }

    a.bh--help {
      --border-size: 2px;

      border: var(--border-size) solid $navy;
      border-radius: 50%;
      color: $navy;
      height: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)) - (2 * var(--border-size)));
      line-height: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)) - (2 * var(--border-size)));
      min-width: auto;
      padding: 0;
      text-align: center;
      text-decoration: none;
      width: calc(var(--header-height-m) - (2 * var(--header-padding-top-bottom-d)) - (2 * var(--border-size)));
    }
  }
}
