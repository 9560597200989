@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';
@use './mixins';

.required-label {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.313rem !important;
}

body {
  .marked-form-label {
    color: colors.$mud-puddle;
    font-size: variables.$font-size-default;
    line-height: variables.$default-size;
    margin-bottom: 1.313rem;
    margin-top: 1rem;
    text-align: left;
  }
}

.mdc-form-field {
  label {
    padding-left: 0 !important;
  }
}

.mat-mdc-checkbox {
  .mdc-form-field {
    align-items: start;
    vertical-align: top;
  }

  .mdc-checkbox__background {
    border-radius: .25rem;
    height: 1.875rem;
    left: 0 !important;
    top: 0 !important;
    width: 1.875rem;
  }

  .mdc-checkbox {
    flex: 0 0 1.875rem;
    height: 1.875rem;
    margin: 0 !important;
    padding: 0 !important;
    width: 1.875rem;

    &:focus-within {
      .mdc-checkbox__background {
        @include mixins.bh-focus-indicator();
      }
    }
  }
}

.checkbox {
  &.mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: .25rem !important;
  }
}

.form {
  .label-field {
    font-size: variables.$font-size-default;
    font-weight: variables.$medium-font-weight;
    line-height: variables.$default-size;
    margin-bottom: .313rem;
    text-align: left;
  }

  .input-field {
    border: .063rem solid colors.$cloudsurf;
    border-radius: variables.$default-spacing;
    color: colors.$charcoal;
    font-size: variables.$font-size-medium;
    font-weight: variables.$medium-font-weight;
    line-height: variables.$default-size;
    margin-bottom: .5rem;
    padding: 1rem;

    &:focus {
      @include mixins.bh-focus-indicator();

      border-color: colors.$dark-blue;
    }

    &::placeholder {
      color: colors.$cloudsurf;
    }

    &--password {
      padding-right: 45px;
    }

    &:read-only,
    &--read-only {
      background: url('../assets/lock.svg') no-repeat 95%;
      background-color: rgb(221 221 221 / .3);
    }
  }

  .radio_field {
    height: 1.875rem;
    margin: 0 .5rem 0 0;
    width: 1.875rem;

    .mdc-radio {
      margin-right: .5rem;
    }

    &.mat-mdc-radio-button label {
      font-size: variables.$font-size-default;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$default-size;
      margin-bottom: .313rem;
      text-align: left;
    }
  }

  .select-field {
    appearance: none;
    background: url('../assets/down-arrow.svg') no-repeat 90%;
    border: .063rem solid colors.$charcoal;
    border-radius: variables.$default-spacing;
    color: colors.$charcoal;
    font-size: variables.$font-size-medium;
    font-weight: variables.$medium-font-weight;
    line-height: variables.$default-size;
    padding: 1rem;

    &:focus {
      @include mixins.bh-focus-indicator();
    }
  }

  .checkmark {
    border: .125rem solid colors.$stormshadow;
    border-radius: .25rem;
    height: 1.875rem;
    margin: 0 1rem 0 0;
    width: 1.875rem;
  }

  input:checked ~ .checkmark {
    background: url('../assets/checked.svg') 0 0 no-repeat;
    border-color: transparent;
  }

  .password {
    &::-ms-reveal {
      display: none;
    }

    &::-webkit-reveal {
      display: none;
    }

    &::-webkit-caps-lock-indicator {
      content: none;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.313rem;
    position: relative;
    text-align: left;

    &:focus-within {
      .label-field {
        color: colors.$dark-blue;
      }
    }

    &__with-icon {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      input + button {
        &.input-field {
          &__icon {
            @include bh-button;

            border-color: transparent;
            height: 45px;
            position: absolute;
            right: 4px;
            top: 7px;

            /* stylelint-disable-next-line max-nesting-depth */
            &--eye {
              background: url('../assets/eye-icon.svg') 50% 50% no-repeat;

              /* stylelint-disable-next-line max-nesting-depth */
              &-crossed {
                background: url('../assets/eye-icon-password.svg') 50% 50% no-repeat;
              }
            }

            &:focus {
              @include mixins.bh-focus-indicator();
            }
          }
        }
      }
    }
  }

  .buttons {
    button {
      cursor: pointer;
    }

    button[disabled] {
      color: colors.$charcoal;
    }
  }

  .text-danger {
    color: colors.$magenta;
    margin-bottom: .25rem;
    position: relative;

    .bi-x-square {
      background: url('../assets/red-cross.svg') 0 0 no-repeat;
      background-position: left;
      background-size: variables.$font-size-default;
      height: variables.$font-size-default;
      margin-top: .25rem;
      position: absolute;
      width: variables.$font-size-default;
    }
  }

  .text-success {
    color: colors.$dark-green;
    margin-bottom: .25rem;
    position: relative;

    .bi-check-square-fill {
      background: url('../assets/green-cross.svg') 0 0 no-repeat;
      background-position: left;
      background-size: variables.$font-size-default;
      height: variables.$font-size-default;
      margin-top: .25rem;
      position: absolute;
      width: variables.$font-size-default;
    }
  }
}

.error-msg {
  color: colors.$magenta;
  font-size: variables.$font-size-normal;
  font-weight: variables.$medium-font-weight;
  line-height: variables.$default-size;
  margin: 0;
  text-align: left;

  .errorimg {
    margin-right: .313rem;
    margin-top: -5px;
    vertical-align: middle;
  }

  p {
    margin: 0;
  }
}

.error-label {
  color: colors.$magenta;
}

// @TODO: unify these styles with existing styles and remove redundancy & inconsistencies
.bh-form {
  text-align: left;

  &__field {
    &__label {
      color: colors.$mud-puddle;
      display: flex;
      font-size: variables.$font-size-default;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$default-line-height;
      margin-bottom: variables.$small-spacing;
    }

    &__input {
      border-color: colors.$cloudsurf;
      border-radius: variables.$default-radius;
      border-style: solid;
      border-width: variables.$border-width-default;
      box-sizing: border-box;
      color: colors.$charcoal;
      font-size: variables.$font-size-medium;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$default-size;
      padding: variables.$large-spacing;
      width: 100%;

      &:focus {
        @include mixins.bh-focus-indicator();
      }
    }

    &__input--radio {
      $radio-button-width: 1.875rem;
      $radio-button-padding: .375rem;
      $radio-button-ripple-width: 3.125rem;

      .mdc-radio__background,
      .mdc-radio__outer-circle {
        height: $radio-button-width;
        width: $radio-button-width;
      }

      .mdc-radio__background {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .mdc-radio__inner-circle {
        background: colors.$dark-blue;
        border-width: 0;
        height: calc(#{$radio-button-width} + #{$radio-button-padding});
        left: calc(-.5 * #{$radio-button-padding});
        top: calc(-.5 * #{$radio-button-padding});
        width: calc(#{$radio-button-width} + #{$radio-button-padding});
      }

      &.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
        height: $radio-button-ripple-width;
        left: calc(50% - (#{$radio-button-ripple-width} / 2));
        top: calc(50% - (#{$radio-button-ripple-width} / 2));
        width: $radio-button-ripple-width;
      }
    }

    &__input-wrapper--search,
    &__input-wrapper--datepicker {
      position: relative;

      .mat-datepicker-toggle {
        position: absolute;
        right: .75rem;
        top: 2.125rem;
      }

      &:focus-within {
        .bh-button--search-icon {
          background-image: url('../assets/search-icon-blue.svg');
        }
      }
    }

    &__select {
      appearance: none;
      background: url('../assets/down-arrow.svg') no-repeat 95%;
      border: variables.$border-width-default solid colors.$charcoal;
      border-radius: variables.$default-spacing;
      color: colors.$cloudsurf;
      font-size: variables.$font-size-medium;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$default-size;
      padding: 1rem;
      width: 100%;

      &.ng-dirty {
        color: colors.$charcoal;
      }

      &:focus {
        @include mixins.bh-focus-indicator();
      }
    }

    &__error-message {
      color: $magenta;
      margin-top: variables.$default-spacing;

      &__icon {
        margin-right: variables.$small-spacing;
      }
    }

    &--invalid {
      .bh-form__field {
        &__label {
          color: colors.$magenta;
        }

        &__input {
          border-color: colors.$magenta;
        }

        &__select {
          border-color: colors.$magenta;
        }
      }
    }

    &:focus-within {
      .bh-form__field {
        &__label {
          color: colors.$dark-blue;
        }

        &__input,
        &__select {
          border-color: colors.$dark-blue;
        }
      }
    }

    &--checkbox {
      // To override these defaults, use a custom class on the mat-checkbox in your component
      color: colors.$charcoal;
      font-size: variables.$font-size-medium;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$large-line-height;

      // stylelint-disable-next-line no-descending-specificity
      label {
        align-items: center;
        display: flex;
        font-size: inherit; // inherit ensures the label text will have the font-size applied to the mat-checkbox
      }

      // stylelint-disable-next-line no-descending-specificity
      .mdc-checkbox {
        margin: 0 1rem 0 0 !important;
      }
    }

    &__input--readonly {
      background: url('../assets/lock.svg') no-repeat 95%;
      background-color: variables.$bg-readonly;
    }
  }
}

.is-invalid {
  input {
    border-color: colors.$magenta !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  label {
    color: colors.$magenta;
  }

  &:focus-within {
    // stylelint-disable-next-line no-descending-specificity
    label {
      color: colors.$magenta !important;
    }
  }
}

input[type='search'] {
  // override any user-agent-defined background color
  background: transparent;
  // hide browser-default "search" and "cancel" buttons
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

input::-webkit-contacts-auto-fill-button {
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

.employersearchbox {
  position: relative;

  .search-icon {
    background: url('../assets/search-icon.svg') 0 0 no-repeat;
    background-size: 100% 100%;
    height: 1.375rem;
    margin: 0;
    opacity: .7;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: .938rem;
    top: 2.938rem;
    width: 1.375rem;
  }

  .searchfield {
    background: transparent;
    border: .063rem solid colors.$mud-puddle;
    border-radius: variables.$default-radius;
    color: inherit;
    font-size: variables.$font-size-medium;
    margin: 0;
    padding: 1rem 2.5rem 1rem 1rem;

    &::placeholder {
      color: colors.$cloudsurf;
      font-size: variables.$font-size-medium;
      font-weight: variables.$medium-font-weight;
      line-height: variables.$default-size;
    }

    &:focus {
      @include mixins.bh-focus-indicator();

      border-color: colors.$dark-blue;
    }

    &:focus + .search-icon {
      background: url('../assets/search-icon-blue.svg') 0 0 no-repeat;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

.mat-mdc-input-element::placeholder {
  color: colors.$cloudsurf;
  opacity: 1;
}
