@use './node_modules/@bh/design-system/src/style/colors';
@use './variables';
@use './mixins';

// Override Angular Material styles to match BH styles
.bh-tabs {
  .mat-mdc-tab-header {
    border: 0;
    display: flex;
    justify-content: flex-end;
  }

  .mat-mdc-tab-label-container {
    flex-grow: 0;
  }

  .mat-mdc-tab-labels {
    background: colors.$light-teal;
    border-radius: variables.$border-radius-tablist;
  }

  .mdc-tab-indicator {
    display: none;
  }

  &__tab {
    &.mdc-tab {
      height: auto;
      min-width: auto;
      opacity: 1;
      padding: variables.$default-spacing;
    }

    .mdc-tab__content {
      border-radius: variables.$border-radius-tab;
      height: auto;
      padding: variables.$default-spacing 1.5rem;
    }

    .mdc-tab__text-label {
      color: colors.$dark-blue;
      font-size: variables.$font-size-normal;
      font-weight: variables.$default-font-weight;
      line-height: variables.$font-size-default;
      transition: none;
    }

    &.mat-mdc-tab {
      &.mdc-tab--active,
      &:hover,
      &:focus {
        .mdc-tab__content {
          background: colors.$dark-blue;
        }

        .mdc-tab__text-label {
          color: variables.$white;
        }

        .mdc-tab__ripple {
          display: none;
        }
      }

      &:focus {
        .mdc-tab__content {
          @include mixins.bh-focus-indicator();
        }
      }
    }
  }

  &__tabpanel {
    &--card-group-wrapper .mat-mdc-tab-body-content {
      // padding plus negative margin ensures cards' box-shadows are completely visible
      margin: 0 -.75rem;
      padding: 1rem;
    }
  }
}
